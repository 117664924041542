// src/utils/auth.js
import auth0 from 'auth0-js'
import { navigate } from 'gatsby'

export const isBrowser = typeof window !== 'undefined'

const NETLIFY_ENV = process.env.CONTEXT || process.env.NODE_ENV
const NETLIFY_SITE_URL = process.env.URL || 'http://localhost:8000'
const NETLIFY_DEPLOY_URL = process.env.DEPLOY_PRIME_URL || NETLIFY_SITE_URL
const isNetlifyProduction = NETLIFY_ENV === 'production'
const siteUrl = isNetlifyProduction ? NETLIFY_SITE_URL : NETLIFY_DEPLOY_URL

const validConfig =
  !!process.env.GATSBY_AUTH0_DOMAIN && !!process.env.GATSBY_AUTH0_CLIENTID

if (!validConfig) {
  console.warn(
    `Please set GATSBY_AUTH0_DOMAIN and GATSBY_AUTH0_CLIENTID in .env.${process.env.NODE_ENV} to render site properly`
  )
}

const auth =
  isBrowser && validConfig
    ? new auth0.WebAuth({
        domain: process.env.GATSBY_AUTH0_DOMAIN,
        clientID: process.env.GATSBY_AUTH0_CLIENTID,
        redirectUri: `${siteUrl}/callback`,
        responseType: 'token id_token',
        scope: 'openid profile email',
      })
    : {}

const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: false,
}

let user = {}

export const isAuthenticated = () => {
  if (!(isBrowser && validConfig)) {
    return
  }

  return localStorage.getItem('isLoggedIn') === 'true'
}

export const login = () => {
  if (!(isBrowser && validConfig)) {
    return
  }

  auth.authorize()
}

const setSession =
  (cb = () => {}) =>
  (err, authResult) => {
    if (err) {
      localStorage.setItem('isLoggedIn', false)
      cb()
      return
    }

    if (authResult && authResult.accessToken && authResult.idToken) {
      let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
      tokens.accessToken = authResult.accessToken
      tokens.idToken = authResult.idToken
      tokens.expiresAt = expiresAt
      user = authResult.idTokenPayload

      localStorage.setItem('isLoggedIn', true)

      const authRedirect = localStorage.getItem('authFromURL')

      if (authRedirect) {
        // Navigate to the page that triggered auth0
        navigate(authRedirect)
        localStorage.removeItem('authFromURL')
      }

      cb()
    }
  }

export const silentAuth = (callback) => {
  if (!isAuthenticated()) return callback()
  auth.checkSession({}, setSession(callback))
}

export const handleAuthentication = () => {
  if (!(isBrowser && validConfig)) {
    return
  }

  auth.parseHash(setSession())
}

export const getProfile = () => {
  return user
}

export const logout = () => {
  localStorage.setItem('isLoggedIn', false)
  auth.logout({ returnTo: siteUrl })
}
